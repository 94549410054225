/**
 * Enum with the supported languages.
 */

export enum Languages {
  /**
   * Spanish language
   */
  ES = 'ES',

  /**
   * English language
   */
  EN = 'EN'
}
